<template>
  <div id="imprint">
    <h1>Imprint</h1>
    <div class="paragraph">
      <p>
        Daniel Gran <br/>
        Germany <br/>
      </p>
      <p> For more information contact me via E-Mail (<router-link to="/contact">Contact</router-link>) </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imprint"
}
</script>

<style scoped>

</style>