<template>
  <div id="home">
    <div class="topper">
      <h1>Daniel<br/> Gran</h1>
      <h2>Discipline is key</h2>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>

.topper {
  position: fixed;
  width: 100vw;
  height: 200vh;
  z-index: 8;
  background: linear-gradient(to bottom right, #fff 0%, #fff 50%, transparent 50%, transparent 140%);
}

.topper h1 {
  color: black;
  overflow: hidden;
}
.topper h2 {
  position: absolute;
  color: white;
  right: 0;
  top: 70vh;
}

</style>