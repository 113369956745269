<template>
  <div id="header">
    <div class="clickables">
      <router-link to="/" class="item">Home</router-link>
      <router-link to="/projects" class="item">Projects</router-link>
      <router-link to="/contact" class="item">Contact</router-link>
      <router-link to="/imprint" class="item">Imprint</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
#header {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 50px;
}


.clickables {
  height: inherit;
  display: flex;
  justify-content: start;
  align-items: center;
}

.item{
  font-size: 1.2rem;
  text-decoration: none;
  margin: 0 10px;
  color: white;
}

.item:hover {
  text-decoration: underline;
}


</style>