import Vue from 'vue'
import VueRouter from "vue-router";
import App from './App.vue'

import Home from '/src/components/Home'
import Contact from "@/components/Contact";
import Imprint from "@/components/Imprint";
import Projects from "@/components/Projects";

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/projects',
      name: 'Projects',
      component: Projects
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/imprint',
      name: 'Imprint',
      component: Imprint
    }
  ],
  mode: 'history'
})

Vue.use(VueRouter)

new Vue({
  el: '#app',
  router: router,
  render: h => h(App),
})
