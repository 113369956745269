<template>
  <div id="contact">
    <h1>Contact</h1>

    <h3>E-Mail</h3>
    <div class="paragraph">
      <p>
        <a :href="'mailto:' + email">{{ email }}</a><br/>
      </p>
    </div>

    <h3>Phone</h3>
    <div class="paragraph">
      <p>
        Mobile: <a :href="'tel:' + phoneNumber">{{ phoneNumber }}</a><br/>
        Landline: <a :href="'tel:' + phoneNumber2">{{ phoneNumber2 }}</a><br/>
        <br/>
        <i class="fa fa-whatsapp" aria-hidden="true"></i>
        <a :href="'https://wa.me/' + phoneNumber">WhatsApp</a>
      </p>
    </div>

    <h3>Other</h3>
    <div class="paragraph">
      <p>
        <a href="https://github.com/danielgran">GitHub</a><br/>
      </p>
      <p>
        <a href="https://twitter.com/danielgrannn">Twitter</a><br/>
      </p>
      <p>
        <a href="https://www.youtube.com/channel/UCYTGCCZs0X1yb1NGCAgLiKA">YouTube</a><br/>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data: function () {

    return {
      phoneNumber: "+49 " + "1579 2399937",
      phoneNumber2: "+49 " + "911 14896321",
      email: "contact@grandaniel.com"
    };
  }
}
</script>

<style scoped>


</style>