<template>
  <div id="contact">
    <h1>Projects</h1>
    <p>Here you can find some of my recent projects i have "completed".</p>
    <ul>
      <li style="font-style: italic">"Katzenfutterautomat 24/7"</li>
      <p>Completion: 02/2020</p>
      <p> I did this during my training as an IT Specialist (Application Development) as a greeting project.<br/>
        Basically it can fill up the food bowl for cats in a specified time interval. <br/>
        <br/>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/5wCXZV_suSE?controls=0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </p>
      <li style="font-style: italic">Native-Tutors</li>
      <p>"Completed": 02/2021</p>
      <p>This was my very first experience in a start-up.<br/>
        In cooperation with the <a href="https://www.5-euro-business.de">"5 Euro Business</a> course we received 5 Euros
        to found our very own company. <br/>
        In a nutshell we tried to offer a zoom.us courses for teachers of schools in germany during the start of the
        pandemic. Unfortunately our selling skills were not existant. <br/>
        It was a rough ride, but all in all it was a great experience beside the fact that we did not made any profit.
        <br/>
        Feel free to watch our video, you should pretty much understand it as long as you are a german speaker.
        <br/>
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/hAgu0dVFaUs?controls=0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </p>
      <li style="font-style: italic">Powershell course</li>
      <p>Completion: 10/2020</p>
      <p>During my training i also set up an Powershell course for the others.</p>

      <li style="font-style: italic">ARMA 3 Life suite</li>
      <p>Completion: 2021</p>
      <p>I did lots of functional programming with the ARMA 3 game engine. SQF is a pretty rough language
      to learn and debugging is just horrific, but i did not stopped until the base framework was somewhat
      completed. The original plan was to build a own life gaming community upon that framework, but on that
      journey i learned so many new things so that my future goals just changed. <br/>
      Here are some links to the repositories:<br/>
        •<a href="https://github.com/danielgran/ArmA3.Extension.Redis">ARMA 3 Redis Extension</a><br/>
        •<a href="https://github.com/danielgran/arma3mysql">ARMA 3 MySQL Extension</a><br/>
        •<a href="https://github.com/danielgran/arma3-life">ARMA 3 SQF Framework</a>
      </p>


      <li style="font-style: italic"><a href="#">Years In Pixels (Out of service)</a></li>
      <p>Completion: 12/2021</p>
      <p>I made this for my ex-girlfriend.</p>
      <p>On that platform you can give your days a color specified to your mood. (like red, yellow and green)<br/>
        After that you can see your whole year in a big table where each cell represents with its color your mood of
        that day. The development of this specify project was eye-opening and gave me many insights of professional
        software development. </p>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Contact",
}
</script>

<style scoped>

li {
  color: white;
}

</style>