<template>
  <div id="app">
    <Header/>
    <router-view/>
  </div>
</template>

<script>

import Header from "@/components/Header";
export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style>
#app {
  margin: 0 0 0 0;
  padding: 0;
}
body {
  font-family: greycliff-cf, sans-serif;
  margin: 0;
  padding: 0;
  background: black;
}
a {
  text-decoration: underline;
}

h1 {
  font-weight: 700;
  font-style: normal;
  font-size: 6rem;
}

h2 {
  font-size: 1.1rem;
}

a, p, h1, h2, h3 {
  color: white
}


</style>
